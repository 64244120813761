
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*追加分*/
html {
    color: #222;
    font-size: 12px;
    scroll-padding-top: 120px;
    /*font-feature-settings: "palt";*/
}

body {
    scroll-padding-top: 120px;
}

#root {
}